<template>
  <div class="container">
    <div class="login">
        <div class="login-info">
            <div class="title">
                 <ul>
                    <li v-for="(item,index) in  menuData" :key="index" @click="clickMenuHandler(index)">
                        <p class="login-title" :style="{color:currentMenuIndex===index?'#F02233':''}">{{item}}</p>
                    </li>
                    <div class="login-title-slider" :style="{left:loginTitleLeftStyle}"></div>
                 </ul>
            </div>
            <div class="user-input-box">
                <!-- 用户名 -->
                <input type="text" class="username" v-model="loginForm.phoneNumber" placeholder="请输入手机号码">
                <!-- 密码登录 -->
                <div v-if="currentMenuIndex===0" class="password-box">
                    <input type="password" class="password" v-model="loginForm.password"  placeholder="请输入密码"/>
                    <div class="show-or-hidden-password-btn" @click="clickShowOrHiddenBtnHandler()">
                        <img class="show-or-hidden-password-icon" :src="showPassword?'/images/login/login_hidden_password_icon.png':'/images/login/login_show_password_icon.png'" alt="隐藏密码">
                    </div>
                    
                </div>
                <!-- 短信登录 -->
                <div v-if="currentMenuIndex===1" class="sms-code ">
                    <input class="password" type="password" v-model="loginForm.code" placeholder="请输入短信验证码">
                    <div class="get-code-btn" @click="clickGetPhoneCodeHandler()">
                        <span>获取短信验证码</span>
                    </div>
                </div>
                <span class="forget-password">忘记密码</span>
            </div>
            <div class="login-btn" @click="loginBtnHandler">
                <span class="login-text">登录</span>
            </div>
            <div class="need-register-or-login">
                <span class="info">没有账号？</span>
                <span class="register" @click="$router.push('/register')">立即注册</span>
            </div>
            <div class="user-agreement">
                <img class="icon-select" @click="clickUerAgreementBtnHandler()" :src="userAgreement?'/images/login/login_user_has_agreement_icon.png':'/images/login/login_user_not_agreement_icon.png'" alt="用户协议">
                <span class="text-1">我已阅读并同意</span>
                <span class="text-2">《用户协议》</span>
                <span class="text-1">和</span>
                <span class="text-2">《隐私政策》</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {login} from '@/services/user/index.js';
import {mapMutations} from 'vuex';
export default {
    data(){
        return{
            currentMenuIndex:0,
            menuData:["密码登录","短信登录"],
            loginForm:{
                phoneNumber:'',
                password:'',
                code:''
            },
            showPassword:true,
            userAgreement:true
        }
    },
    methods:{
        ...mapMutations("user",["saveUserToken"]),
        //点击菜单操作
        clickMenuHandler(index){
            this.currentMenuIndex = index;
        },
        //点击是否显示密码按钮
        clickShowOrHiddenBtnHandler(){
            this.showPassword = ! this.showPassword;
        },
        //点击用户协议按钮
        clickUerAgreementBtnHandler(){
            this.userAgreement = !this.userAgreement;
        },
        //登录
       async loginBtnHandler(){  //&&/^\d{6}$/.test(this.loginForm.code)
          if(!(/^1[3456789]\d{9}$/.test(this.loginForm.phoneNumber)&&this.loginForm.password.trim().length>=6)){
                this.$message.info('请不要调皮，好好填写相关信息')
                return;
          }
          this.loginForm.type = this.currentMenuIndex+1; //密码登录type为1，短信登录type为2
          try{
            const {data} = await  login(this.loginForm);
            if(data.code === 200){
                //this.$store.commit('saveUserToken',data.data)
                this.saveUserToken(data.data);
                this.$router.push({path:'/myCenter/myProduct'});
                this.$store.commit('home/setCurrentGlobalMenuIndex',7)
                this.$message.success('登录成功，欢迎来到天榜号平台');
            }else{
                this.$message.warning('用户名和密码不正确');
            }
          }catch(err){}
        },
        //点击获取短信验证码操作
        clickGetPhoneCodeHandler(){
            //先同意条款
            if(!this.userAgreement){
                this.$message.warning("请先同意《用户协议》与《隐私政策》")
            }

        }
    },
    computed:{
        loginTitleLeftStyle(){
            return this.currentMenuIndex===0?'86px':'220px';
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    // width: calc(100vw);
    // height: calc(100vh);
    .login{
        margin: 0 auto;
        width: 1200px;
        position: relative;
        .login-info{
            position: absolute;
            top:100px;
            right:0;
            width:400px;
            height: 426px;
            background: #FFFFFF;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title{
                width: 400px;
                height: 56px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.03);
                border-radius: 12px 12px 0px 0px;
                ul{
                    margin-left: 30px;
                    width: 340px;
                    list-style-type: none;
                    padding: 0px;
                    display: flex;
                    position: relative;
                    cursor: default;
                    li{
                        margin-left: 70px;
                        height: 56px;
                        list-style: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .login-title{
                            font-family: MicrosoftYaHei, MicrosoftYaHei;
                            font-weight: bold;
                            font-size: 16px;
                            color: #424242;
                            line-height: 16px;
                            text-align: right;
                            font-style: normal;
                        }
                        &:hover .login-title{
                            color: #F02233;
                        }
                    }
                    .login-title-slider{
                        position: absolute;
                        left: 86px;
                        bottom: 0px;
                        width: 30px;
                        height: 2px;
                        background: #F02233;
                        transition: all 0.3s ease-in-out;
                    }
                }

            }

            .user-input-box{
                padding: 30px 30px 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .username{
                    width: 340px;
                    height: 46px;
                    border: 1px solid #EEEEEE;
                    border-radius: 4px;
                    padding-left: 16px;

                    &:focus{
                            outline: none;
                            border: 1px solid #F02233;
                        }

                    &::placeholder{
                        font-size: 14px;
                        font-family: MicrosoftYaHei;
                        color: #C3C3C3;
                    }
                }
                .password-box{
                    margin-top: 30px;
                    width: 340px;
                    height: 46px;
                    position: relative;
                    .password{
                        width: 340px;
                        height: 46px;
                        border: 1px solid #EEEEEE;
                        border-radius: 4px;
                        padding-left: 16px;
                        &:focus{
                            outline: none;
                            border: 1px solid #F02233;
                        }

                        &::placeholder{
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #C3C3C3;
                        }
                    }
                    .show-or-hidden-password-btn{
                        width: 40px;
                        height: 46px;
                        border-radius: 0px 4px 4px 0px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .show-or-hidden-password-icon{
                            width: 16px;
                            height: 12px;
                        }
                    }
                }

                .sms-code{
                    width: 340px;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;
                    .password{
                        width: 190px;
                        height: 46px;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #EEEEEE;
                        padding-left: 16px;

                        &:focus{
                            outline: none;
                            border: 1px solid #F02233;
                        }

                        &::placeholder{
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #C3C3C3;
                        }
                    }
                    .get-code-btn{
                        width: 130px;
                        height: 46px;
                        background: #F02233;
                        border-radius: 4px;
                        line-height: 46px;
                        text-align: center;
                        cursor: default;
                        span{
                            font-size: 14px;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            color: #FFFFFF;
                        }
                    }
                }

                .forget-password{
                    margin-top: 20px;
                    font-size: 12px;
                    font-family: MicrosoftYaHei;
                    color: #8E8E8E;
                }
            }

            .login-btn{
                margin: 20px 30px 0;
                width: 340px;
                height: 46px;
                background: #F02233;
                border-radius: 4px;
                line-height: 46px;
                text-align: center;
                cursor: default;
                .login-text{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }

            .need-register-or-login{
                margin-top: 20px;
                cursor: default;
                .info{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #424242;
                }
                .register{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #F02233;
                }
            }

            .user-agreement{
                margin-top: 30px;
                width: 340px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: default;
                .icon-select{
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
                .text-1{
                    font-size: 12px;
                    font-family: MicrosoftYaHei;
                    color: #949494;
                }
                .text-2{
                    font-size: 12px;
                    font-family: MicrosoftYaHei;
                    color: #7693B4;
                }
            }

        }
    }

}
</style>